.card-wrapper{
    padding: 40px 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    box-sizing: border-box;

}

.tile-wrapper{
    box-sizing: border-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 7px #00000012;
    border-radius: 4px;
    opacity: 1;
}