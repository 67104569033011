$bgWhite: #F8F8FF;
$primary : #35A9F4;
$accent : #FCFC00;
$textColor : #666666;

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./src/assets/fonts/Poppins-Light.ttf) format('ttf');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./src/assets/fonts/Poppins-Medium.ttf) format('ttf');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./src/assets/fonts/Poppins-Regular.ttf) format('ttf');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./src/assets/fonts/Poppins-Bold.ttf) format('ttf');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./src/assets/fonts/Poppins-ExtraBold.ttf) format('ttf');
}
  