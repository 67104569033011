@import 'variables';
.home_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #F8F8F8!important;
}

.home_container{
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}
.nav_wrapper{
    padding-left:20px;
    padding-right:20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: 65px;
}
.nav_list{
    display: flex;
    flex: 1;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    margin-top: 20px;
    .nav_link{
        box-sizing: border-box;
        text-decoration: none;
        margin-bottom: 0px;
        padding: 10px 0;
        display:inline-block;
        width: 120px;
    }
}
.list_item{
    box-sizing: border-box;
    list-style: none;
    display: inline-block;
    text-align: left;
    font: normal normal 400 14px/21px Poppins;
    letter-spacing: 0px;
    color: #313622;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 10px;
    &:hover{
        border-bottom: 1px solid #F28500; 
        color: #F28500;
        margin-bottom: -1px;
        letter-spacing: 0px;
        text-shadow:0 0 1px white;
    }
}

.active{
    border-bottom: 1px solid #F28500; 
    color: #F28500;
    margin-bottom: -1px;
    letter-spacing: 0px;
    text-shadow:0 0 1px white;
 }

 .nav_action{
    margin-right: 20px;
    align-self: center;
 }

 ._navbtn{
    padding: 10px 15px;
    border-radius: 4px;
 }

 .register_modal {
    display: flex;
    // width: 420px;
    flex-direction: column;
    p{
      text-align: left;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
      font-size : 14px;
      margin-top: 0;
      color: $textColor;
      margin-bottom: 20px;
      font-weight: lighter;
      font: normal normal 300 14px/24px Poppins;
    }
    label{
      color: #404040;
      text-align: left;
      font: normal normal 400 13px/17px Poppins;
      letter-spacing: 0px;
      color: #404040;
      opacity: 1;
      margin-bottom: 10px;
    }
    h3 {
        font-family: Roboto;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
      }

      h4{
        text-align: left;
        font: normal normal bold 14px/24px Poppins;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
      }



}
.pic{
    outline: none;
    border: none;
    background: #00439921 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #1378E8;
    padding-left: 20px;
    width: 100%;
    img{
        margin-right: 20px;
    }
}
.sbt{
    background: $primary 0% 0% no-repeat padding-box;;
    width: 200px;
    height: 40px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;  color: #fff;
    text-align: center;
    font: normal normal 600 14px/17px Poppins;
    letter-spacing: 0px;
    color: #FCFC00;
    text-transform: capitalize;
    opacity: 1;  outline: none;
    font-size: 14px;
    margin-top: 20px;
    font-style: normal;
    cursor: pointer;
    font-weight: 900;
    border: none;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    align-self: flex-end;
}

#home{
    background-color : #F8F8F8;
    .s_h_container{
        @media  only screen and (max-width: 700px) {
            padding: 0;    
        }
        padding: 100px 0;
        position: relative;
        .s_h_bg{
            height: 300px;
            right: -40px;
            top: 100px;
            position: absolute;
            @media  only screen and (max-width: 700px) {
                position: relative;
                margin: 80px 0;
                img{
                width: 300px;}

            }
        }

    }
}
.cont_w850{
    width: 850px!important;
    margin: 0 auto;
    @media  only screen and (max-width: 700px) {
        width: 90%!important;
        margin: 0 20px;
    }
}
.f_1{
    flex: 1;
}
.text_content{
    h1{
        font-family: 'Poppins';
        font-size: 70px;
        font-weight: 600;
        line-height: 84px;
        letter-spacing: 0em;
        text-align: left;
        color: #14171A;
        @media  only screen and (max-width: 700px) {
            font-size: 36px;
            line-height: 44px;
            text-align: center;
        }
    }
    p{
        position: absolute;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
}
#products, #partners, #team{
    background-color: #fff;
    width: 100%;
    h3{
        color: #F28500;
        font-family: 'Poppins';
        font-size: 30px;
        font-weight: 500;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: center;
    }
}
.s_p_container{
    display: flex;
    @media  only screen and (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}
._item_partner{
    padding: 100px 0;
    @media  only screen and (max-width: 700px) {
        text-align: center;
    }
    img{
        height: 45px;
    }
    h4{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 0px;
        color: #414141;
    }
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #5E5B5B;
    }
}
.s_pa_container{
    display: flex;
    justify-content: start;
    align-items: center;
    img{
        margin-right: 30px;
    }
    @media  only screen and (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.s_t_container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    @media  only screen and (max-width: 700px) {
        flex-direction: column;;
    }
}

.person_card{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    .avtr_{
        width: 80px;
        height: 80px;
        background-color: #e9e7e7;
        border-radius: 100%;
        margin-bottom: 20px;
    }
    h5{
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 500;
        margin: 10px;
        letter-spacing: 0em;
        text-align: left;
        color: #414141;
        
    }
    span{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #9F9F9F;
    }
}
._bg{
    .avtr_{
        width: 120px;
        height: 120px;
    }
}

._bg_pic{
    background-image: url('../../assets/images/contact.svg');
    margin-bottom: 40px;
    background-position: right;
    background-size: 500px 400px;
    background-repeat: no-repeat;
    height: 400px;
    position: relative;
}

._card{
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 400px;
    top: 50px;
    height: 300px;
    position: absolute;
    h3{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 22px;
        color: #F28500;
    }
    p{  font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 32px;
        color: #9F9F9F;
        
    }
    button{
        position: absolute;
        width: 203px;
        height: 50px;
        outline: none;
        border: none;
        color: #FFF;
        cursor: pointer;
        background: #000000;
    }
}
.input_{
    input, textarea{
        background: none;
        border: 1px solid #5E5B5B;
        border-radius: 0;
        margin-bottom: 10px;
        &:hover, &:focus{
            background: none;
            border: 1px solid #5E5B5B;
            border-radius: 0;
        }
    }
    .row_input{
        display: flex;
    }
    textarea{
        width: 100%;
        resize: none;
        height: 150px;
        padding-left: 20px;
        padding-top: 20px;
        &:hover, &:focus{
            background: none;
            border: 1px solid #5E5B5B!important;
            border-radius: 0;
        }
    }
}

.black_btn{
    width: 203px;
    height: 50px;
    outline: none;
    border: none;
    color: #FFF;
    cursor: pointer;
    background: #000000;
    margin: 50px 0;
}

._sep_{
    display: flex;
    @media  only screen and (max-width: 700px) {
        flex-direction: column;
    }
    .sid_1{
        flex: 1;
    }
}
#visit-us{
    background-color: #F0F0F0;
    height: 300px;
}

#bottom{
   background-color: #F28500; 
   padding-top: 20px;
   padding-bottom: 20px;
}
.div_map{
    width: 90%;
    height: 90%;
}
.sid_1, .sid_2{
    h3{
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        color: #414141;
    }
}

.sid_2{
    display: flex;
    flex-direction: column;
    .social_icon{
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
        span{
            font-family: 'Poppins';
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0em;
            text-align: left;
            color: #414141;
        }
        img{
            width: 20px;
            margin-right: 20px;
        }
    }
}
.div_contact_info{
    margin-top: 30px;
}