@import 'variables';

.m_container{
  display: flex;
}


.link{
  text-decoration: none;
  color: inherit;
}

.main{
  flex: 1;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 700px) {
    max-width: 100%;
  }
}

  .mod_content_body{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    .head_modal{
      margin: 0;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: center;
      color: #000;
    }
    .input_less{
      border: 2px solid #0E348A;
      border-radius: 3px;
      text-align: center;
      padding-left: 10px;
    }
}

.add_user_actios{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
  button{
      height: 40px;
      outline: none;
      font-family: Roboto;
      cursor: pointer;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      padding-left: 40px;
      padding-right: 40px;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: center;
      border-radius: 3px;
  }
  .a_cancel{
      border: 2px solid #9C9C9C;
      color: #9C9C9C;
  }
  .a_confirm{
      color: #fff;
      background-color: #0C8444;
      border: none;
  }
}


._g_colored{
  color: #0C8444!important;
}
.m_t_20{
  margin-top: 20px;
}
.m_t_40{
  margin-top: 40px;
}
.a_btn{
  height: 50px;
  outline: none;
  font-family: Roboto;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  color: #fff;
  border: none;
  font-weight: 700;
  padding-left: 80px;
  padding-right: 80px;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 3px; 
}
.d-flex {
  display: flex!important;
}
.s-btwn {
  justify-content: space-between!important;
}
p{
  margin-top: 0;
}
body {
  min-height: 100%!important;
  position: relative;
  font: 'Poppins';
}

.header-title{
  text-align: left;
  letter-spacing: 0px;
  color: $primary;
  opacity: 1;
  font-size: 30px;
  font: normal normal 600 30px/46px Poppins;
  img{
    cursor: pointer;
  }
}

._btn{
  background: #F28500 0% 0% no-repeat padding-box;;
  text-align: center;
  font: normal normal 400 14px/17px Poppins;
  letter-spacing: 0px;
  color: #FFF;
  text-transform: capitalize;
  opacity: 1;  outline: none;
  font-size: 13px;
  font-style: normal;
  cursor: pointer;
  border: none;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px 30px;
}

.txt_{
  text-align: center;
  font: normal normal 300 16px/22px Poppins;
  letter-spacing: 0px;
  color: #212121DE;
  opacity: 1;
  font-size: 14px;
}

