@import 'variables';

.topbar {
  width: 100%;
  height: 90px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #FFFFFF!important;
  box-shadow: 0px 4px 5px 0px #00000005!important;
  @media only screen and (max-width: 950px) and (min-width: 700px) {
    justify-content: flex-start;
    padding: 0 20px;
  }
}

.topbar_wrapper{
  width: 850px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  @media only screen and (max-width: 950px) and (min-width: 700px) {
    width: 650px;
    margin: 0;
  }

  @media screen and (max-width: 700px) {
    padding: 0 20px; 
    justify-content: space-between;
  }
}

.list_item{
  box-sizing: border-box;
  list-style: none;
  display: inline-block;
  text-align: left;
  font: Poppins;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0px;
  color: #313622;
  text-transform: uppercase;
  opacity: 1;
  padding-bottom: 10px;
  &:hover{
      border-bottom: 1px solid #F28500; 
      color: #F28500;
      margin-bottom: -1px;
      letter-spacing: 0px;
      text-shadow:0 0 1px white;
      cursor: pointer;
  }
}

.active{
  border-bottom: 1px solid #F28500; 
  color: #F28500;
  margin-bottom: -1px;
  letter-spacing: 0px;
  text-shadow:0 0 1px white;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: #D5DAC3;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
  @media  only screen and (max-width: 700px) {
    img{
      width: 100px;
    }
  }
  @media only screen and (max-width: 950px) and (min-width: 700px) {
    img{
      width: 80px;
    }
  }
}
._menu_items{
  @media screen and (max-width: 700px) {
    display: none; 
  }
  flex: 1;
  .menu_li{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  
  80% {
    transform: scale(1.07);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mb_nav{
  width: 100%;
  height: calc(100vh + 70px) ;
  background-color: #FFF;
  position: absolute;
  top: -70px;
  animation: 0.8s ease-out 0s 1 slideInFromLeft;
  display: none;
  @media  only screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
}

.show{
  display: flex;
  flex-direction: column;
}

.hide{
  display: none;
  // animation: 1.0s ease-in 0s 1 slideInFromRight; 
}

.top_l_phone{
  margin-top: 100px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: 1.0s ease-out 0s 1 slideInFromLeftItems;
}
.nav_wrapper_phone{
  margin-top: 80px;
  animation: 1.4s ease-out 0s 1 slideInFromLeftItems;
}
.x{
  color: #F28500;
  font-size: 20px;
  cursor: pointer;
}
.nav_list_phone{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  .nav_link{
    li{
    color: #000;
    border: none;
    font-size: 16px;
    margin-bottom: 20px;
  }
  }
}

.ham{
  display: none;
  @media  only screen and (max-width: 700px) {
   display: unset;
  }
  img{
    width: 30px;
    cursor: pointer;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    background-color: #E6E6E6
  }

  25% {
    background-color: #ECECEC;
  }

  50% {
    background-color: #F2F2F2;
  }

  75% {
    background-color: #F9F9F9
  }

  100% {
    transform: translateX(0);
    background-color: #FFF
  }
}

@keyframes slideInFromLeftItems {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


