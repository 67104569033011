.input-wrapper{
    display: flex;
    flex: 0;
    box-sizing: border-box;
}
input,input:focus{
    outline: none;
    border: none;
    background: #103F7B14 0% 0% no-repeat padding-box;
    border-radius: 6px;   
     width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 15px;
    color: #00132C;
}
img{
    margin-right: 10px;
}
.sml{
    width : 18px
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #414141;
    font-weight: 600;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #414141;
   opacity:  1;
   font-weight: 600;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #414141;
   opacity:  1;
   font-weight: 600;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #414141;
   font-weight: 600;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #414141;
   font-weight: 600;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #414141;
   font-weight: 600;
}